import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

import SEO from '../components/SEO'
import Layout from '../components/Layout'

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return 'Not a preview!'

  return (
    <Layout>
      <SEO noIndex />
      <div style={{ padding: '150px 0px' }}>
        <p>Loading preview...</p>
      </div>
    </Layout>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
